export const environment = {
  production: true,
  // apiBaseUrl: 'https://merchantkyc.vidcom.in/server/public/index.php/api',
  // appBaseUrl: 'https://merchantkyc.vidcom.in/client'



  apiBaseUrl: 'https://uat.vsts.net.in/server/public/index.php/api',
  appBaseUrl: 'https://uat.vsts.net.in/client'




};

// 'https://merchantkyc.vidcom.in/server/public/index.php/api/AsmVerificationListAdminExcel'


// 'https://merchantkyc.vidcom.in/client/theme/admin-asm-reports'

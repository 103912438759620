<div class="
    wrapper
    vertical-layout
    page-header-light
    vertical-menu-collapsible vertical-gradient-menu
    preload-transitions
    1-column
    login-bg
    blank-page blank-page
  ">
  <div class="container">
    <div class="d-flex justify-content-center h-100">
      <div class="card" style="width: 408px; height: 350px">
        <div class="card-header">
          <h3 class="logo">OTP Verification</h3>
          <p> OTP was sent to {{this.data.mobile}}. please check your mobile phone and verify </p>
          <p> If you have not get any OTP please resend &nbsp;&nbsp;&nbsp;<a style="color: blue;cursor: pointer;" (click)="ResendOtp()" >Resend OTP</a>   </p>

        </div>
        <div class="card-body">
          <form class="form-horizontal form-material" (submit)="verifyOpt()" [formGroup]="otpForm">
           <div class="input-group form-group">
              <input type="text" class="form-control form-control-otp m-2" tabindex="1" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" appOtpVerification maxlength="1" [(ngModel)]="otp.input1" (keyup)="moveNext()" [ngModelOptions]="{standalone: true}" />
              <input type="text" class="form-control form-control-otp m-2" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" appOtpVerification maxlength="1" [(ngModel)]="otp.input2" (keyup)="moveNext()" [ngModelOptions]="{standalone: true}" />
              <input type="text" class="form-control form-control-otp m-2" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" appOtpVerification maxlength="1" [(ngModel)]="otp.input3" (keyup)="moveNext()" [ngModelOptions]="{standalone: true}" />
              <input type="text" class="form-control form-control-otp m-2" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" appOtpVerification maxlength="1" [(ngModel)]="otp.input4" (keyup)="moveNext()" [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="form-group">
              <button class="btn float-right login_btn" type="submit">
                Verify OTP
              </button>
              <!-- <input type="submit" value="Login" class="btn float-right login_btn"> -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute, ChildActivationStart, ActivationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthjwtService } from '../services/authjwt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  user:any
  token:any
  checkLogin: boolean = false;
  constructor(private route: Router){
    this.token = sessionStorage.getItem('token')
    if(this.token){
      this.checkLogin = true;
    }else{
      this.checkLogin = false;
    }
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log(this.checkLogin,'this.checkLogin')
      if (!this.checkLogin) {
        this.route.navigate(['']);
        return false;
      }
      return true;
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { VideoKycComponent } from './video-kyc/video-kyc.component';
import { VideoResponseComponent } from './video-response/video-response.component';
import { AadharOtpPaysprintComponent } from './aadhar-otp-paysprint/aadhar-otp-paysprint.component';
import { AadharResponseComponent } from './aadhar-response/aadhar-response/aadhar-response.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';


const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  {
    path: 'theme',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
  },
  {
    path: 'staff',
    canActivate: [AuthGuard],
    loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule)
  },
  {
    path: 'staff-dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./staff-dashboard/staff-dashboard.module').then(m => m.StaffDashboardModule)
  },
  { path: 'video-kyc', component: VideoKycComponent },
  { path: 'video-response', component: VideoResponseComponent },
  { path: 'aadhar-response', component: AadharResponseComponent },
  { path: 'aadhar-ps-otp', component: AadharOtpPaysprintComponent },
  { path: 'aadhar-non-otp', loadChildren: () => import('./aadhar-non-otp/aadhar-non-otp.module').then(m => m.AadharNonOtpModule) },
  { path: 'aadhar-otp', loadChildren: () => import('./choose-aadhar/choose-aadhar.module').then(m => m.ChooseAadharModule) },
  { path: 'video-verification', loadChildren: () => import('./video-verification/video-verification.module').then(m => m.VideoVerificationModule) },
  { path: '404-not-found', component: ErrorpageComponent },
  { path: '**', redirectTo: '404-not-found' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgxUiLoaderModule } from 'ngx-ui-loader';

import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AadharOtpComponent } from './aadhar-otp/aadhar-otp.component';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { VideoKycComponent } from './video-kyc/video-kyc.component';
import { VideoResponseComponent } from './video-response/video-response.component'; 
import { GoodpipePipe } from './hello/goodpipe.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgSelect2Module } from 'ng-select2';
import { SharedModule } from './shared/shared.module';
import { OtpComponent } from './component/otp/otp.component';
import {OtpVerificationDirective } from './directive/otpverification.directive';
import { AadharOtpPaysprintComponent } from './aadhar-otp-paysprint/aadhar-otp-paysprint.component'
import { AadharValidationDirective } from './directive/aadhar-validation.directive';
import { AadharResponseComponent } from './aadhar-response/aadhar-response/aadhar-response.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
//import { AngularFontAwesomeModule } from 'angular-font-awesome';


@NgModule({
  declarations: [
    AppComponent,
    // AadharOtpComponent,
    VideoKycComponent,
    VideoResponseComponent,
    AadharResponseComponent,
    GoodpipePipe,
    OtpComponent,
    OtpVerificationDirective,
    AadharValidationDirective,
    AadharOtpPaysprintComponent,
    ErrorpageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatCardModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgSelect2Module,
    SharedModule,
    NgxUiLoaderModule,
    FormsModule, 
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
    AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };
  constructor(private http: HttpClient,private userService : UserService) { }

  getAdminDashboard(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl +'/adminDashboard',formData);
  }

  findtaskReports(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/getTaskAdmin', formData);
  }

  addtask(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/taskCreate', formData);
  }

  getTaskById(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/getTaskByIdAdmin', formData);
  }

  taskapprove(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/taskApproved', formData);
  }

  taskreject(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/taskRejected', formData);
  }

  taskunlock(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/unlockTask', formData);
  }

  bulkupload(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/bulkTaskUpload', formData);
  }

  findAsmReports(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/AsmVerificationListAdmin', formData);
  }


  findReports(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/findReports', formData);
  }

  updateThresholdSettings(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/updateThresholdSettings', formData);
  }

  addAppLink(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/addAppLink', formData);
  }

  updateAppLink(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/updateAppLink', formData);
  }

  getThresholds(){
    let token = this.userService.getToken();
    return this.http.get(environment.apiBaseUrl + '/getThresholds?token='+token);
  }

  getAppLink(){
    let token = this.userService.getToken();
    return this.http.get(environment.apiBaseUrl + '/getAppLink?token='+token);
  }

  getAppLinkkData(id){
    let token = this.userService.getToken();
    return this.http.get(environment.apiBaseUrl + '/getAppLinkkData?token='+token+'&id='+id);
  }
   deleteForm(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/deleteForm', formData);
  }

  // Send aadhar otp paysprint

  Sendaadharotp(formData){
    // formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/AadharOtpMethod', formData);
  }
  Veifyaadharotp(formData){
    return this.http.post(environment.apiBaseUrl + '/VerifyAadharOtp', formData);
  }


  updateswitchapiSettings(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/updateswitchapiSettings', formData);
  }

  getswitchapiSettings(){
    let token = this.userService.getToken();
    return this.http.get(environment.apiBaseUrl + '/getswitchapiSettings?token='+token);
  }

  // getKycData(kycFormId){
  //   let token = this.userService.getToken();
  //   return this.http.get(environment.apiBaseUrl + '/getKycData?kycFormId='+kycFormId+'&token='+token);
  // }
  deleteAsmId(formData){
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/deleteAsmForm', formData);
  }
}

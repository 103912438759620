import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { AuthjwtService } from 'src/app/services/authjwt.service';
import { LoginComponent } from 'src/app/login/login.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  otpForm: FormGroup;
  user: any;

    otp = {
      input1: '',
      input2: '',
      input3: '',
      input4: '',
    }

  constructor(
    private ngxloader: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private UserService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jwtauth: AuthjwtService,
    private router: Router,
    private dialogRef: MatDialogRef<LoginComponent>

  ) {
    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.maxLength, Validators.pattern('^[0-9]*$')]],
    });
  }

  ngOnInit(): void {
    console.log(this.data)
  }

  moveNext() {

  }

  ResendOtp() {
    this.UserService.login(this.data).subscribe(
      (data) => {
        console.log(data)
      })
  }

  verifyOpt() {
    const otpList = this.otp.input1 + '' + this.otp.input2 + '' + this.otp.input3 + '' + this.otp.input4;
    if (otpList.length == 4) {
      this.data['otp'] = otpList;
      delete this.data['mobile'];
      this.ngxloader.start();
      this.UserService.verifyOtp(this.data).subscribe((e: any) => {
        this.ngxloader.stop();
        if (e.statuscode == 201) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: e.message,
          })
        } else if (e.statuscode == 200) {
          if (e.status && e.is_model == 0) {
            this.dialogRef.close();
            sessionStorage.setItem('token', e.token);
            this.user = this.jwtauth.isAuthenticatedjwttoken();
            setTimeout(() => {
              if (this.user.type == 1 || this.user.type == 3) {
                this.router.navigate(['theme/admin-dashboard']);
              } else {
                this.router.navigate(['staff-dashboard']);
              }
            }, 1000);

          } else if (e.status && e.is_model == 1) {
            Swal.fire({
              title: 'Regret',
              text: "Sorry For Inconvenience",
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Use this Url'
            }).then((result) => {
              if (result.value) {
                window.open(e.redirect_url);
              }
            })
          }
        }
      }, (err) => {
        this.ngxloader.stop();
      })
    }


  }

  get f() {
    return this.otpForm.controls;
  }

}

<div class="aadhar-otp">
    <form *ngIf="aadharotpform" id="aadharotmfrm" #namedElement [formGroup]="AadharOtpFormGroup"   method="POST" (ngSubmit)="aadharOtpSubmit()">
          <h1>Enter Aadhaar Number For Verification</h1>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group form-group"> 
                    <input type="text" class="form-control" (addharNumber)="aadharVal($event)" [maxlength]="12"  placeholder="Enter Your Aadhar no" (keypress)="numberOnly($event)" appAadharValidation   formControlName="aadhar">
                </div>
                <div class="form-group">
                    <button class="button" [disabled]="!AadharOtpFormGroup.valid" type="submit">SEND OTP</button> 
                </div>
            </div>
        </div>   
           
    </form>
    

    <form *ngIf="showotp" id="showotp" #showotpElement [formGroup]="verifyOtpFormGroup"   method="POST" (ngSubmit)="VerifyaadharOtpSubmit()">
        <h1>Enter Aadhaar OTP </h1>
      <div class="row">
          <div class="col-md-12">
              <div class="input-group form-group aadharotp-wrap"> 
                  <!-- <input type="text" class="form-control aadharotp"  [maxlength]="1"  placeholder=""   formControlName="otp">
                  <input type="text" class="form-control aadharotp"  [maxlength]="1"  placeholder=""   formControlName="otp">
                  <input type="text" class="form-control aadharotp"  [maxlength]="1"  placeholder=""   formControlName="otp">
                  <input type="text" class="form-control aadharotp"  [maxlength]="1"  placeholder=""   formControlName="otp">
                  <input type="text" class="form-control aadharotp"  [maxlength]="1"  placeholder=""   formControlName="otp">
                  <input type="text" class="form-control aadharotp"  [maxlength]="1"  placeholder=""   formControlName="otp"> -->

              <input type="tel" class="form-control form-control-otp m-2"  (keypress)="numberOnly($event)"  tabindex="1" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" appOtpVerification maxlength="1" [(ngModel)]="otp.input1" (keyup)="moveNext()" [ngModelOptions]="{standalone: true}" />
              <input type="tel" class="form-control form-control-otp m-2" (keypress)="numberOnly($event)" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" appOtpVerification maxlength="1" [(ngModel)]="otp.input2" (keyup)="moveNext()" [ngModelOptions]="{standalone: true}" />
              <input type="tel" class="form-control form-control-otp m-2"  (keypress)="numberOnly($event)"  maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" appOtpVerification maxlength="1" [(ngModel)]="otp.input3" (keyup)="moveNext()" [ngModelOptions]="{standalone: true}" />
              <input type="tel" class="form-control form-control-otp m-2" (keypress)="numberOnly($event)"  maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" appOtpVerification maxlength="1" [(ngModel)]="otp.input4" (keyup)="moveNext()" [ngModelOptions]="{standalone: true}" />
              <input type="tel" class="form-control form-control-otp m-2" (keypress)="numberOnly($event)"  maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" appOtpVerification maxlength="1" [(ngModel)]="otp.input5" (keyup)="moveNext()" [ngModelOptions]="{standalone: true}" />
              <input type="tel" class="form-control form-control-otp m-2" (keypress)="numberOnly($event)"  maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" appOtpVerification maxlength="1" [(ngModel)]="otp.input6" (keyup)="moveNext()" [ngModelOptions]="{standalone: true}" />
              </div>
              <div style="float: right;" >
                <small>
                    <p *ngIf="displayTimer">  didn't get the otp {{ display }}</p>
                        <button *ngIf="resendOtp" class="resendbutton" (click)="resendotp()">Resend OTP</button>    
                </small>
              </div>
              <div class="form-group" style="margin-top: 4rem;">
                  <button class="button" [disabled]="verifyOtpbutton" type="submit">VERIFY OTP</button> 
              </div>
          </div>
      </div>   
         
  </form>
  {{verifydata | json}}
  
    <div class="container-fluid" *ngIf="aadharotploader">
      <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="divLoading">
                <p>
                  <img src="assets/ajax-loading.gif">
                    please wait...
                </p>
            </div>
        </div>
      </div>
    </div>

     
  </div>
  
  
  
   
  
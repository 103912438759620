
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EkycService } from 'src/app/services/ekyc.service';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-aadhar-response',
  templateUrl: './aadhar-response.component.html',
  styleUrls: ['./aadhar-response.component.css'],
})
export class AadharResponseComponent implements OnInit {
  loader: Boolean = false;
  status: any;
  apiUrlVar: string;
  switch_type:string;
  agent_token: any;
  requestIdResponse: any;
  uuidResponse: any;
  userIdResponse: any;
  statusResponse: any;
  hashResponse: any;
  redirectSuccUrl: Boolean = false;
  messageText: string;
  msg: string = '';

  constructor(
    private route: ActivatedRoute,
    private ekycService: EkycService
  ) {}

  ngOnInit(): void {
    this.switch_type = sessionStorage.getItem('switch_type');
    this.apiUrlVar = environment.apiBaseUrl;
    setTimeout(() => {
      if(this.switch_type === "paysprint"){
        this.route.queryParams.subscribe((params) => {
          if(params['status'] == "success"){
            this.msg = "Aadhar OTP Success ( आधार OTP प्रक्रिया पूर्ण। )";
          }else if(params['status'] == "failed"){
            this.msg = "Aadhar OTP Failed ";
          }else{
            this.msg = "Something went wrong!!";
          }
        })
      }else{
        this.route.queryParams.subscribe((params) => {
          this.agent_token = params["agent_token"];
          sessionStorage.setItem("agent_token", this.agent_token);
          this.requestIdResponse = params["requestId"];
          this.userIdResponse = params["userId"];
          this.uuidResponse = params["uuid"];
          this.hashResponse = params["hash"];
          this.statusResponse = params["status"];
          // this.msg               = params['msg'];
        });

        this.msg =
          localStorage.getItem("msg") !== "undefined"
            ? localStorage.getItem("msg")
            : "";

        if (
          typeof this.statusResponse !== "undefined" &&
          this.statusResponse == "FAIL"
        ) {
          // this.messageText = 'Aadhar Otp failed';
          localStorage.setItem("msg", "Aadhar Otp failed");
          window.location.replace(
            environment.appBaseUrl + "/aadhar-response/?status=failed"
          );
        }

        if (
          typeof this.requestIdResponse !== "undefined" &&
          typeof this.userIdResponse !== "undefined" &&
          typeof this.uuidResponse !== "undefined" &&
          typeof this.hashResponse !== "undefined" &&
          typeof this.statusResponse !== "undefined"
        ) {
          localStorage.removeItem("msg");
          this.msg = "";
          this.loader = true;
          this.ekycService
            .saveAadharOtpResponse({
              requestId: this.requestIdResponse,
              userId: this.userIdResponse,
              uuid: this.uuidResponse,
              hash: this.hashResponse,
              status: this.statusResponse,
            })
            .subscribe(
              (data) => {
                this.messageText = data["message"] ? data["message"] : "";
                if (data["status"]) {
                  console.log(data["message"]);
                  this.agent_token = data["agent_token"];
                  this.addharExtraction();
                } else {
                  localStorage.setItem("msg", data["message"]);
                  window.location.replace(
                    environment.appBaseUrl + "/aadhar-response/?status=failed"
                  );
                }
              },
              (err) => {
                console.log(err);
              },
              () => {
                //console.log('request completed!');
              }
            );
        }
      }
    }, 2000);

  }

   addharExtraction() {
    this.ekycService
      .addharExtraction({ agent_token: this.agent_token })
      .subscribe(
        (data) => {
          this.messageText = data['message'] ? data['message'] : '';
          if (data['status']) {
            this.loader = false;
            this.checkMatchers();
          } else {
            localStorage.setItem('msg', data['message']);
            window.location.replace(
              environment.appBaseUrl + '/aadhar-response/?status=failed'
            );
          }
        },
        (err) => {
          this.loader = false;
          Swal.fire({
            title: 'Somethig went wrong, try again',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Proceed',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              this.loader = true;
              this.addharExtraction()
            }
          });
          console.log(err);
        },
        () => {
         //console.log('request completed!');
        }
      );
  }

  checkMatchers() {
    this.loader = true;
    this.ekycService.checkMatchers({ agent_token: this.agent_token }).subscribe(
      (data) => {
        this.loader = false;
        this.messageText = data['message'] ? data['message'] : '';
        if (data['status']) {
          localStorage.setItem('msg', data['message']);
          window.location.replace(
            environment.appBaseUrl + '/aadhar-response/?status=success'
          );
        } else {
          if(typeof data['result']['api_status'] != 'undefined' ){
            localStorage.setItem('msg', data['message']);
            window.location.replace(
              environment.appBaseUrl + '/aadhar-response/?status=failed'
            );
          }
          else if(typeof data['partialMatch'] != 'undefined'){
            localStorage.setItem('msg', data['message']);
            window.location.replace(
              environment.appBaseUrl + '/aadhar-response/?status=mismatch'
            );
            // Swal.fire({
            //   title: 'Do you want to proceed ?',
            //   html: data['message'],
            //   icon: 'warning',
            //   confirmButtonColor: '#3085d6',
            //   confirmButtonText: 'Proceed',
            //   allowOutsideClick: false,
            // }).then((result) => {
            //   if (result.value) {
            //     this.ekycService
            //       .continueWithName({ mobile: data['mobile'] })
            //       .subscribe(
            //         (data) => {
            //           if (data['status']) {
            //             this.loader = false;
            //             localStorage.setItem('msg', 'Aadhar Verification Compeleted Successfully');
            //             console.log(data['message']);
            //             window.location.replace(
            //               environment.appBaseUrl +
            //                 '/aadhar-response/?status=success'
            //             );
            //           } else {
            //             this.loader = false;
            //             localStorage.setItem('msg', data['message']);
            //             console.log(data['message']);
            //             window.location.replace(
            //               environment.appBaseUrl +
            //                 '/aadhar-response/?status=failed'
            //             );
            //           }
            //         },
            //         (err) => {
            //           console.log(err);
            //         },
            //         () => {
            //          //console.log('request completed!');
            //         }
            //       );
            //   }
            // });
          }

        }
      },
      (err) => {
        console.log(err);
      },
      () => {
       //console.log('request completed!');
      }
    );
  }
}

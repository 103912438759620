import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { AuthjwtService } from '../services/authjwt.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user:any;
  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  constructor(private http: HttpClient,private router: Router,
    public jwtauth: AuthjwtService,) { }

  getUserProfile() {
    return this.http.get(environment.apiBaseUrl + '/userProfile');
  }

  login(authCredentials) {
    return this.http.post(environment.apiBaseUrl + '/login', authCredentials,this.noAuthHeader);
  }

  verifyOtp(authCredentials) {
    return this.http.post(environment.apiBaseUrl + '/verifyotp', authCredentials,this.noAuthHeader);
  }

  setToken(token: string) {
    sessionStorage.setItem('token', token);
  }

  getToken() {
    return sessionStorage.getItem('token');
  }

  deleteToken() {
    sessionStorage.removeItem('token');
  }

  getSessionToken() {
    return sessionStorage.getItem('session_token');
  }

  deleteSessionToken() {
    sessionStorage.removeItem('session_token');
  }

  logMeOut(){
    return this.http.post(environment.apiBaseUrl + '/logout',{"token":this.getToken()});
  }

  getUserPayload() {
    var token = this.getToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    }
    else
      return null;
  }

  isLoggedIn() {
    var userPayload = this.getUserPayload();
    if (userPayload)
      return userPayload.exp > Date.now() / 1000;
    else
      return false;
  }
  validatePage(){
    if(sessionStorage.getItem("token")){
      if(this.jwtauth.isAuthenticated()){
        const formData = new FormData();
        formData.append('token', sessionStorage.getItem("token"));
        this.http.post(environment.apiBaseUrl +'/checkToken',formData).subscribe(
          data => {
            if(!data['status']){
              sessionStorage.removeItem("token");
              this.router.navigate(['login']);
            }else{
              this.user =  this.jwtauth.isAuthenticatedjwttoken();
              if(this.user.type == 1 || this.user.type == 3){
                this.router.navigate(['/theme/admin-dashboard']);
              }else{
                this.router.navigate(['staff']);
              }
            }
          }, err =>{
            console.log(err);
          }, () =>{
              console.log('Request Completed!');
            }
        );
      }else{
        sessionStorage.removeItem("token");
        this.router.navigate(['login']);
      }
    }
    else {
      if(sessionStorage.getItem("session_token")){
        this.router.navigate(['dashboard']);
      }
      else{
        this.router.navigate(['login']);
      }
    }
  }
}

<div class="aadhar-res">

    <div class="container-fluid" *ngIf="loader">
        <div class="row">
          <div class="col-lg-12 col-md-12">
              <div class="divLoading">
                  <p>
                    <img src="assets/ajax-loading.gif">
                      Your Verification is in progess, please wait...
                  </p>
              </div>
          </div>
        </div>
    </div>
    <div *ngIf="msg !=''">
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,700,700i&display=swap" rel="stylesheet">
        <h1>Verification Result</h1>
        <p>{{(msg != '')? msg : ''}}</p><br>
    </div>
    
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { UserService } from '../services/user.service';
import { BehaviorSubject } from 'rxjs';
//import { RegisterModel } from '../modules/models/register.model';

@Injectable({
  providedIn: 'root',
})
export class EkycService {
  seterUserLoginDtl = new BehaviorSubject(null);
  geterUserLoginDtl = this.seterUserLoginDtl.asObservable();

  // getdataretailer() {
  //   return this.userDataSource.asObservable();
  // }

  noAuthHeader = { headers: new HttpHeaders({ NoAuth: 'True' }) };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient, private userService: UserService) {}
  setdataretailer(dataObj) {
    console.log(dataObj);
    this.seterUserLoginDtl.next(dataObj);
  }
  getUserProfile() {
    return this.http.get(environment.apiBaseUrl + '/userProfile');
  }
  getAsmList(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/AsmVerificationList',
      formData
    );
  }
  getPincodesData(formData){
    return this.http.post(environment.apiBaseUrl + '/getPincodesData', formData)
  }

  getAsmByID(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/AsmVerificationGetById',
      formData
    );
  }
  unlockAsmForm(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/unlockAsmForm', formData);
  }


  getBusinessInfo(kycFormId:any) {

    let token = this.userService.getToken();
    return this.http.get(
      environment.apiBaseUrl +
        '/getKycBusinessDetail?kycFormId=' +
        kycFormId +
        '&token=' +
        token
    );

  }
  performAsmActions(formData, type){
    formData.append('token', this.userService.getToken());

    if(type == 'Approve'){

    return this.http.post(
      environment.apiBaseUrl + '/AsmVerificationApproved',
      formData
    );
    }else{
      return this.http.post(
        environment.apiBaseUrl + '/AsmVerificationReject',
        formData
      );
    }
  }
  //Old Kyc
  bioMetricOldSubmit(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/biometricPendingVerification',
      formData,
      this.noAuthHeader
    );
  }
  SearchKycData(SearchFormData) {
    return this.http.post(
      environment.apiBaseUrl + '/searchKyc',
      SearchFormData
    );
  }
  SearchKyc(SearchFormData) {
    return this.http.post(
      environment.apiBaseUrl + '/SearchKycForm',
      SearchFormData
    );
  }

  mobileVerify(formData) {
    formData.append('session_token', this.userService.getSessionToken());
    return this.http.post(
      environment.apiBaseUrl + '/mobileVerify',
      formData,
      this.noAuthHeader
    );
  }

  agentMobileVerify(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/agentMobileVerify',
      formData,
      this.noAuthHeader
    );
  }

  confirmForm(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/confirmForm',
      formData,
      this.noAuthHeader
    );
  }

  addPanDetails(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/addPanDetails',
      formData,
      this.noAuthHeader
    );
  }

  uploadPanPhoto(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/uploadPanPhoto',
      formData,
      this.noAuthHeader
    );
  }

  updatePanDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/updatePanDetails',
      formData
    );
  }

  addBasicDetails(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/addBasicDetails',
      formData,
      this.noAuthHeader
    );
  }

  addDistributorDetails(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/addDistributorDetails',
      formData,
      this.noAuthHeader
    );
  }

  updateBasicDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/updateBasicDetails',
      formData
    );
  }

  updateMobile(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/updateMobile', formData);
  }

  addResidentialDetails(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/addResidentialDetails',
      formData,
      this.noAuthHeader
    );
  }

  updateResidentialDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/updateResidentialDetails',
      formData
    );
  }

  addBusinessDetails(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/addBusinessDetails',
      formData,
      this.noAuthHeader
    );
  }

  updateBusinessDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/updateBusinessDetails',
      formData
    );
  }

  getimageRcodeDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/copyfile', formData);
  }

  addBankDetails(formData) {
    formData.append('session_token', this.userService.getSessionToken());
    return this.http.post(
      environment.apiBaseUrl + '/addBankDetails',
      formData,
      this.noAuthHeader
    );
  }

  updateBankDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/updateBankDetails',
      formData
    );
  }

  addAddressProof(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/addAddressProof',
      formData
    );
  }

  updateResidentialProof(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/updateResidentialProof',
      formData
    );
  }

  addKycMode(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/addKycMode',
      formData,
      this.noAuthHeader
    );
  }

  updateKycMode(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/updateKycMode', formData);
  }

  getKycData(kycFormId) {
    let token = this.userService.getToken();
    return this.http.get(
      environment.apiBaseUrl +
        '/getKycData?kycFormId=' +
        kycFormId +
        '&token=' +
        token
    );
  }

  getKycDataView(kycFormId) {
    let token = this.userService.getToken();
    return this.http.get(
      environment.apiBaseUrl +
        '/getKycDataView?kycFormId=' +
        kycFormId +
        '&token=' +
        token
    );
  }

  getBankList() {
    return this.http.get(
      environment.apiBaseUrl + '/getBankList',
      this.noAuthHeader
    );
  }

  getQualifications() {
    return this.http.get(
      environment.apiBaseUrl + '/getQualifications',
      this.noAuthHeader
    );
  }

  getWorkExperienceList() {
    return this.http.get(
      environment.apiBaseUrl + '/getWorkExperienceList',
      this.noAuthHeader
    );
  }

  getAccTypeList() {
    return this.http.get(
      environment.apiBaseUrl + '/getAccTypeList',
      this.noAuthHeader
    );
  }

  getPincodes() {
    return this.http.get(
      environment.apiBaseUrl + '/getPincodes',
      this.noAuthHeader
    );
  }

  unlockForm(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/unlockForm', formData);
  }

  rejectForm(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/rejectForm', formData);
  }

  approvePanDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/approvePanDetails',
      formData
    );
  }

  rejectPanDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/rejectPanDetails',
      formData
    );
  }

  approveBasicDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/approveBasicDetails',
      formData
    );
  }

  rejectBasicDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/rejectBasicDetails',
      formData
    );
  }

  approveResidentialDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/approveResidentialDetails',
      formData
    );
  }

  rejectResidentialDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/rejectResidentialDetails',
      formData
    );
  }

  approveBusinessDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/approveBusinessDetails',
      formData
    );
  }

  rejectBusinessDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/rejectBusinessDetails',
      formData
    );
  }

  approveBankDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/approveBankDetails',
      formData
    );
  }

  rejectBankDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/rejectBankDetails',
      formData
    );
  }

  approveAddressProof(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/approveAddressProof',
      formData
    );
  }

  rejectAddressProof(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/rejectAddressProof',
      formData
    );
  }

  getDistributorList(formData) {
    formData.append('session_token', this.userService.getSessionToken());
    return this.http.post(
      environment.apiBaseUrl + '/getDistributorList',
      formData,
      this.noAuthHeader
    );
  }
  addSecAddressProofRemarks(formData) {
    formData.append( 'token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/addSecAddressProofRemarks',
      formData,
      this.noAuthHeader
    );
  }

  getAadharOtpCredentials(agent_token) {
    return this.http.get(
      environment.apiBaseUrl +
        '/getAadharOtpCredentials?agent_token=' +
        agent_token,
      this.noAuthHeader
    );
  }

  getPartnerAadharOtpCredentials(mobile) {
    return this.http.get(
      environment.apiBaseUrl +
        '/getPartnerAadharOtpCredentials?mobile=' +
        mobile,
      this.noAuthHeader
    );
  }

  saveAadharOtpResponse(initWebVIKYCResponse) {
    let body = JSON.stringify(initWebVIKYCResponse);
    return this.http.post(
      environment.apiBaseUrl + '/saveAadharOtpResponse',
      body,
      this.httpOptions
    );
  }

  savePartnerAadharOtpResponse(initWebVIKYCResponse) {
    let body = JSON.stringify(initWebVIKYCResponse);
    return this.http.post(
      environment.apiBaseUrl + '/savePartnerAadharOtpResponse',
      body,
      this.httpOptions
    );
  }

  continueWithName(data) {
    let body = JSON.stringify(data);
    return this.http.post(
      environment.apiBaseUrl + '/continueWithName',
      body,
      this.httpOptions
    );
  }

  addharExtraction(data) {
    let body = JSON.stringify(data);
    return this.http.post(
      environment.apiBaseUrl + '/addharExtraction',
      body,
      this.httpOptions
    );
  }

  checkMatchers(data) {
    let body = JSON.stringify(data);
    return this.http.post(
      environment.apiBaseUrl + '/checkMatchers',
      body,
      this.httpOptions
    );
  }

  continueWithPartnerName(data) {
    let body = JSON.stringify(data);
    return this.http.post(
      environment.apiBaseUrl + '/continueWithPartnerName',
      body,
      this.httpOptions
    );
  }

  getVideoKycUrl(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/getVideoKycUrl',
      formData,
      this.noAuthHeader
    );
  }

  getPartnerVideoKycUrl(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/getPartnerVideoKycUrl',
      formData,
      this.noAuthHeader
    );
  }

  getVideoVerification(data) {
    let body = JSON.stringify(data);
    return this.http.post(
      environment.apiBaseUrl + '/getVideoVerification',
      body,
      this.httpOptions
    );
  }

  getPartnerVidVerificationResponse(data) {
    let body = JSON.stringify(data);
    return this.http.post(
      environment.apiBaseUrl + '/getPartnerVidVerificationResponse',
      body,
      this.httpOptions
    );
  }

  checkVideoResponse(data) {
    let body = JSON.stringify(data);
    return this.http.post(
      environment.apiBaseUrl + '/checkVideoResponse',
      body,
      this.httpOptions
    );
  }

  panFetchResponse(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/panFetchResponse',
      formData
    );
  }

  panExtractionResponse(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/panExtractionResponse',
      formData
    );
  }

  addressProofResponse(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/addressProofResponse',
      formData
    );
  }

  getBankVerificationResponse(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/getBankVerificationResponse',
      formData
    );
  }

  getVideoVerificationResponse(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/getVideoVerificationResponse',
      formData
    );
  }

  getBiometricResponse(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/getBiometricResponse',
      formData
    );
  }

  approveVideoDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/approveVideoDetails',
      formData
    );
  }

  rejectVideoDetails(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/rejectVideoDetails',
      formData
    );
  }

  getForgeryResponse(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/getForgeryResponse',
      formData
    );
  }

  addRemarks(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/addRemarks', formData);
  }

  allremarks(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/getRemarks', formData);
  }

  getRemarkData(remarkFormId) {
    let token = this.userService.getToken();
    return this.http.get(
      environment.apiBaseUrl +
        '/getRemarkData?remarkFormId=' +
        remarkFormId +
        '&token=' +
        token
    );
  }

  updateRemark(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/updateRemark', formData);
  }

  getRemarksByType(type) {
    let token = this.userService.getToken();
    return this.http.get(
      environment.apiBaseUrl +
        '/getRemarksByType?type=' +
        type +
        '&token=' +
        token
    );
  }

  allkycforms(formData) {
    formData.append('session_token', this.userService.getSessionToken());
    return this.http.post(environment.apiBaseUrl + '/allkycforms', formData);
  }

  changeRemarkStatus(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/changeRemarkStatus',
      formData
    );
  }

  getAgentCodeByRequestId(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/getAgentCodeByRequestId',
      formData,
      this.noAuthHeader
    );
  }

  getImageQualityResponse(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/getImageQualityResponse',
      formData
    );
  }

  getNameMatchResponse(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/getNameMatchResponse',
      formData
    );
  }

  bioMetricSubmit(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/biometricVerification',
      formData,
      this.noAuthHeader
    );
  }

  doBiometric(formData) {
    return this.http.post(environment.apiBaseUrl + '/doBiometric', formData);
  }

  onboarding(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/cspOnBoardingWithRBL',
      formData
    );
  }

  getRBLConsentStatusData(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/getRBLConsentStatusData',
      formData
    );
  }

  SearchUserData(SearchFormData) {
    return this.http.post(
      environment.apiBaseUrl + '/searchUsers',
      SearchFormData
    );
  }

  updateUser(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/updateUser', formData);
  }

  getAgentError(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/getAgentError', formData);
  }

  getUserData(userId) {
    let token = this.userService.getToken();
    return this.http.get(
      environment.apiBaseUrl +
        '/getUserData?userId=' +
        userId +
        '&token=' +
        token
    );
  }

  getUserRole() {
    let token = this.userService.getToken();
    return this.http.get(
      environment.apiBaseUrl + '/getUserRole?token=' + token
    );
  }

  changeUserStatus(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/changeUserStatus',
      formData
    );
  }

  addUser(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/addUser', formData);
  }

  getUserByToken() {
    let token = this.userService.getToken();
    return this.http.get(
      environment.apiBaseUrl + '/getUserByToken?token=' + token
    );
  }

  getSignzyApi() {
    let token = this.userService.getToken();
    return this.http.get(
      environment.apiBaseUrl + '/getSignzyApi?token=' + token
    );
  }

  doBiometricv1(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/doBiometricv1',
      formData,
      this.noAuthHeader
    );
  }

  bioMetricSubmitv1(formData) {
    return this.http.post(
      environment.apiBaseUrl + '/biometricVerificationv1',
      formData,
      this.noAuthHeader
    );
  }

  getAllBankVerificationResponse(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/getAllBankVerificationResponse',
      formData
    );
  }

  getSecondaryProof(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/getSecondaryProof',
      formData
    );
  }
  approveSecondaryAddressProof(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/approveSecAddressProof',
      formData
    );
  }
  rejectSecAddressProof(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/rejectSecAddressProof',
      formData
    );
  }

  rblRequeryFromPortal(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/rblRequeryFromPortal',
      formData
    );
  }

  addSecAddressProof(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/addSecAddressProof',
      formData
    );
  }

  uploadVideo(formData) {
    return this.http.post(environment.apiBaseUrl + '/uploadVideo', formData);
  }

  getSignzyLog(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/getSignzyLog', formData);
  }

  addCustomRemark(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(
      environment.apiBaseUrl + '/addCustomRemark',
      formData
    );
  }

  getLockedData(formData) {
    formData.append('token', this.userService.getToken());
    return this.http.post(environment.apiBaseUrl + '/getLockedData', formData);
  }

  getVidThresholds() {
    // let token = this.userService.getToken();
    return this.http.get(environment.apiBaseUrl + '/getVidThresholds');
  }

  chooseaddhartype(agent_token, key) {
    const formData = new FormData();
    formData.append('method', key);
    formData.append('agent_token', agent_token);
    return this.http.post(
      environment.apiBaseUrl + '/getAadharMethod',
      formData
    );
  }

  checkMatchersFornOnOtp(agent_token){
    const formData = new FormData();
    formData.append('agent_token', agent_token);
    return this.http.post(
      environment.apiBaseUrl + '/nonOtpManualApproval',
      formData
    );
  }

  checkoptionforAadhar(agent_token){
    const formData = new FormData();
    formData.append('agent_token', agent_token);
    return this.http.post(
      environment.apiBaseUrl + '/checkVerificationMethod',
      formData
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, CanActivate } from '@angular/router';
import { EkycService } from '../services/ekyc.service';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { AdminService } from '../services/admin.service';
declare var $: any;

@Component({
  selector: 'app-video-kyc',
  templateUrl: './video-kyc.component.html',
  styleUrls: ['./video-kyc.component.css'],
  providers:[EkycService]
})
export class VideoKycComponent implements OnInit {
  agent_token: any;
  mobileNumber: any;
  loader:any;
  videoVerificationMode:any;
  videoloader:Boolean=true;

  constructor(
    private route: ActivatedRoute,
    private ekycService:EkycService,
    private adminService:AdminService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.agent_token = params['agent_token'];
      this.mobileNumber = params['mobile'];
      sessionStorage.setItem("agent_token", this.agent_token);
      console.log(this.agent_token,'this.agent_token')
    });

    // var androidV = null;
    // var ua = navigator.userAgent;
    // if (ua.indexOf("Android") >= 0) {
    //     androidV = parseFloat(ua.slice(ua.indexOf("Android") + 8));
    // }
    // if(this.mobileNumber == '9454177756' || this.mobileNumber == '9454177751' || this.mobileNumber == '8595683838'){
      this.loader = true;
      this.getThresholds();
      // window.location.replace(environment.appBaseUrl+'/video-verification?mobile='+this.mobileNumber)
    // }
    // else{
      // this.getVideoUrl();
    // }
  }

  getThresholds(){
    this.ekycService.getVidThresholds().subscribe(
      data => {
        if(data['status']) {
          this.loader=false;
          if(data['result'] != null && data['result'] != ''){
            // this.nameMatchMinValue  = data['result']['nameMatchMinValue'];
            // this.nameMatchMaxValue  = data['result']['nameMatchMaxValue'];
            // this.imgQualityMinValue = data['result']['imgQualityValue'];
            // this.videoMatchValue    = data['result']['videoMatchValue'];
            // this.addressMatchValue  = data['result']['addressMatchValue'];
            this.videoVerificationMode  = Math.floor(data['result']['videoVerificationMode']);
            console.log(this.videoVerificationMode)
            if(this.videoVerificationMode == 1){
              console.log(environment.appBaseUrl+'/video-verification?agent_token='+this.agent_token+'&mobile='+this.mobileNumber)
              // debugger;
              // window.location.replace(environment.appBaseUrl+'/video-verification?mobile='+this.mobileNumber)
              window.location.replace(environment.appBaseUrl+'/video-verification?agent_token='+this.agent_token+'&mobile='+this.mobileNumber)
            }
            else {

              this.getVideoUrl();
            }
          }

        }else{
          this.loader=false;
        }
      },
      err => {
        console.log(err);
        if(err['status'] == 401){
          window.location.replace(err['error']['returnURL']);
        }
      },() =>{
        console.log('request completed!');
      }
    );
  }

  getVideoUrl(){
    this.loader = true;
    const formData = new FormData();
    formData.append('agent_token', this.agent_token);
    formData.append('mobile', this.mobileNumber);
    formData.append('device', 'mobile');
    this.ekycService.getVideoKycUrl(formData).subscribe(
      data => {
        if(data['status']){
          window.location.replace(data['result']['videoUrl']);
        }else{
          //do something
          window.location.replace(environment.appBaseUrl+'/video-response?status=failed&Webmsg='+data['message'])
        }
      },
      err => {
       	console.log(err);

      },() =>{
        console.log('request completed!');
      }
    );
  }
}

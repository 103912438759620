<div class="video-kyc">
  <div class="container-fluid" *ngIf="loader">
    <div class="row">
      <div class="col-lg-12 col-md-12">
          <div class="divLoading">
              <h1>Video Kyc</h1>
              <p>
                <img src="assets/ajax-loading.gif">
                  You are being redirected, please wait...
              </p>
          </div>
      </div>
    </div>
  </div>
</div>




import { Component } from '@angular/core';
import { Router, ActivatedRoute, CanActivate } from '@angular/router';
import { AuthjwtService } from './services/authjwt.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'E-kyc';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public jwtauth: AuthjwtService,
    ) { }

  ngOnInit(): void {
  }
}

import { Component, OnInit,Inject, Renderer2, ElementRef,HostListener,AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-imageviewer',
  templateUrl: './imageviewer.component.html',
  styleUrls: ['./imageviewer.component.css']
})
export class ImageviewerComponent implements OnInit {

  firstimage:any
userimage=[]
filtered:any=[]
id:any
  constructor(
     @Inject(MAT_DIALOG_DATA) public data:any,
  private dialogref:MatDialogRef<ImageviewerComponent>,
  private renderer: Renderer2, private el: ElementRef
  ) { }




  // @HostListener('document:keydown', ['$event'])
  i=0

  carouselElement:any
  value:any
  handleArrowKeys(event?: KeyboardEvent): void {
     this.carouselElement = document.getElementById('carouselExampleControls');
    if (this.carouselElement) {
      if (event.key === 'ArrowLeft') {
        this.carouselElement.dispatchEvent(new Event('prev.bs.carousel'));
      } else if (event.key === 'ArrowRight') {
        this.userimage[this.i]
        this.i++
        // console.log(this.i)
        // console.log(this.userimage[0])
        // console.log(this.userimage[1])
        // console.log(this.userimage[2])
        // console.log(this.userimage[3])
        // console.log(this.userimage[4])
    this.value=this.userimage[this.i]
       console.log(this.userimage[this.i])
        this.carouselElement.dispatchEvent(new Event('next.bs.carousel'));
      }
    }
  }

  ngOnInit(): void {
    this.userimage=this.data?.imgarray
    this.filtered = this.userimage.filter(function (el) {
      return el != '';
    });
    this.id=this.data?.id
    this.firstimage=this.userimage[this.id-1];
    this.userimage.shift()
  }
closePopup(){
this.dialogref.close()
}
}

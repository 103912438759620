import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EkycService } from '../services/ekyc.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-video-response',
  templateUrl: './video-response.component.html',
  styleUrls: ['./video-response.component.css']
})
export class VideoResponseComponent implements OnInit {

  mobileNumber: any;
  loader:any;
  status:any;
  webViewMessage: string = '';

  constructor(
    private route: ActivatedRoute,
    private ekycService:EkycService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.mobileNumber = params['mobile'];
      this.status = params['status'];
      this.webViewMessage = (typeof params['Webmsg'] !== 'undefined') ? params['Webmsg'] : '';
      if(typeof this.status =='undefined'){
        localStorage.removeItem("Webmsg");
        var outerInstance = this;
        this.loader = true;
        setTimeout(function(){
          outerInstance.getVideoVerification(); }, 15000);
        // this.getVideoVerification();
      }
      else {
        console.log(localStorage.getItem("Webmsg"))
        // this.webViewMessage = (params['Webmsg']) ? params['Webmsg'] : '';
        // this.webViewMessage = localStorage.getItem("Webmsg") !=='undefined' ? localStorage.getItem("Webmsg") : '';
      }
  });
  }


  getVideoVerification() {
    this.loader = true;
    // const formData = new FormData();
    // formData.append('mobile', this.mobileNumber);
    this.ekycService.getVideoVerification({mobile: this.mobileNumber}).subscribe(
      data => {

        console.log(data['webViewMessage'])
        if(data['status']) {
          // this.loader = false;
          if(typeof data['result'].videoVerification !=='undefined' && data['result'].videoVerification != null && data['result'].videoVerification){
            localStorage.setItem("Webmsg", data['webViewMessage']);
            localStorage.setItem("chk", 'abc');
            window.location.replace(environment.appBaseUrl+'/video-response?status=success&Webmsg='+data['webViewMessage'])
          }else{
            localStorage.setItem("Webmsg", data['webViewMessage']);
            window.location.replace(environment.appBaseUrl+'/video-response?status=failed&Webmsg='+data['webViewMessage'])
          }
        }
        else {
          localStorage.setItem("Webmsg", data['webViewMessage']);
          // this.loader = false;
          window.location.replace(environment.appBaseUrl+'/video-response?status=failed&Webmsg='+data['webViewMessage'])
        }
      },
      err => {
        window.location.replace(environment.appBaseUrl+'/video-response?status=failed&Webmsg=Something Went Wrong, please try again');
        localStorage.setItem("Webmsg", 'Something Went Wrong');
       	console.log(err);
      },() =>{
        console.log('request completed!');
      }
    );
  }

}

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-aadhar-otp-paysprint',
  templateUrl: './aadhar-otp-paysprint.component.html',
  styleUrls: ['./aadhar-otp-paysprint.component.css']
})
export class AadharOtpPaysprintComponent implements OnInit {
  loader: Boolean = false;
  AadharOtpFormGroup: FormGroup;
  verifyOtpFormGroup:FormGroup;
  aadharotpform: Boolean = true;
  aadharotploader: Boolean = false;
  addharNumber:any;
  refid:any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  showotp:boolean= false;
verifyOtpbutton:boolean= false;
display: any;
resendOtp: boolean = false;
displayTimer: boolean = false;
  verifydata:any;
  kycrefid:any;
  otp = {
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: '',
  }
  refrenceId:any;
  constructor(
    private aadhars : AdminService,
    private _snackBar: MatSnackBar,
    private activatedRoute:ActivatedRoute,
    private router: Router
  ) {
    this.AadharOtpFormGroup = new FormGroup({
      aadhar: new FormControl('',Validators.compose([Validators.required])),
    });
    this.verifyOtpFormGroup = new FormGroup({
      otp: new FormControl('',[Validators.pattern('^[0-9]*$')]),
    });
   }

  ngOnInit(): void {
    this.kycrefid = JSON.parse(atob(sessionStorage.getItem('aadhardet')));

    setTimeout(() => {
      this.refrenceId = this.activatedRoute.snapshot.queryParamMap.get('token');

    }, 2);


  }
  aadharOtpSubmit() {
    if (this.AadharOtpFormGroup.invalid) {
      return false;
    }else{
      this.refid = 'VSTS'+Math.floor(100000000 + Math.random() * 900000000);
      this.aadharotploader = true;
      let data = new FormData();
      data.append('aadharno',this.addharNumber);
      data.append('refid', this.refid);
      data.append('kyc_ref_Id',this.kycrefid);
      this.aadhars.Sendaadharotp(data).subscribe(
        (data) => {
          if(data['status']){
            this.aadharotploader = false;
            this.showotp = true;
            this.aadharotpform = false;
            this.start(1);
            Swal.fire({
              icon: 'success',
              text: data['message'],
            })
          }else{
            this.aadharotploader = false;
            Swal.fire({
              icon: 'error',
              text: data['message'],
            })
          }
        },
        (err) => {
          this.aadharotploader = false;
          console.log(err);
        },
        () => {
          this.aadharotploader = false;
          console.log('request completed!');
        }
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['mat-snack-bar-container'],
    });
  }
  aadharVal(event: any) {
    console.log("AADHAR VALUE ",event);
    this.addharNumber = event;
  }
  // onAadharValueClear(event: any){
  //   if(event){
  //     this.aadharNUmber = '';
  //     this.aadharTrannsaction.patchValue({
  //       aadhar: ''
  //     });
  //   }
  // }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  moveNext() {

  }
  VerifyaadharOtpSubmit(){
    const otpList = this.otp.input1 + '' + this.otp.input2 + '' + this.otp.input3 + '' + this.otp.input4 + '' + this.otp.input5 + '' + this.otp.input6;
    if (otpList.length == 6) {
      this.verifyOtpbutton = true;
      this.aadharotploader = true;
      let fdata = new FormData();
      fdata.append('otp', otpList);
      fdata.append('refid', this.refid);
      fdata.append('requestId', this.refrenceId);
      fdata.append('kyc_ref_Id',this.kycrefid);
      this.aadhars.Veifyaadharotp(fdata).subscribe(
        (data) => {
          if(data['status']){
            this.aadharotploader = false;
            this.verifydata = data['data'];
            window.location.replace(
                        environment.appBaseUrl + '/aadhar-response?status=success'
                      );
            Swal.fire({
              icon: 'success',
              title: 'Oops...',
              text: data['message'],
            })
          }else{
            this.aadharotploader = false;
            window.location.replace(
              environment.appBaseUrl + '/aadhar-response?status=failed'
            );
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: data['message'],
            })
          }
        })
    }

  }
  start(minute) {
    this.displayTimer = true;
    this.resendOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == 0) {
        console.log('finished');
        clearInterval(timer);
        this.resendOtp = true;

        this.displayTimer = false;
      }
    }, 1000);
  }

  resendotp(){
    this.aadharOtpSubmit();
  }
}

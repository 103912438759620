<div class="">
    <img class="img" src="assets/closeicon.png" alt="" (click)="closePopup()">


    <div id="carouselExampleControls" class="carousel slide " data-ride="carousel" >

        <div class="carousel-inner">
            <div class="carousel-item active">
                <img class="d-block w-100" [src]="firstimage" alt="First slide">
            </div>
            <ng-container *ngFor="let img of filtered">
            <div class="carousel-item " *ngIf="img && img != null && img !=undefined && img != '' ">
                    <img class="d-block w-100" [src]="img" alt="Second slide">
            </div>
        </ng-container>

        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" (keydown)="handleArrowKeys($event)">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" (keydown)="handleArrowKeys($event)">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>

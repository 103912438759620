import { Injectable } from '@angular/core';
import { JwtHelper } from 'angular2-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthjwtService {

  constructor() { }

  jwtHelper: JwtHelper = new JwtHelper();


	public isAuthenticatedjwttoken(): any {
		const token = sessionStorage.getItem('token');
		const decoded = this.jwtHelper.decodeToken(token);
		return decoded;
	}

	public isAuthenticated(): boolean {
	    const token = sessionStorage.getItem('token');
	    if(token == null || this.jwtHelper.isTokenExpired(token)){
	    	return false;
	    }else {
	    	return true;
	    }
	}
}
